import { ReportQueue } from "./../pages/report-queue";
import { Diagnostics } from "./../pages/diagnostics";
import { autoinject } from "aurelia-framework";
import { AuthenticationService } from "./authentication-service";
import { IVeterinarian } from "interfaces/entity-interfaces";

export enum DataServiceControllers {
    Dashboard = "Dashboard",
    Patients = "Patients",
    Veterinarians = "Veterinarians",
    SpeciesAndBreeds = "SpeciesAndBreeds",
    Clinics = "Clinics",
    Diagnostics = "Diagnostics",
    ReviewQueue = "ReviewQueue",
    ReportQueue = "ReportQueue",
    Accessions = "Accessions",
    LabRequisitions = "LabRequisitions",
    PathologistComments = "PathologistComments",
    LabResults = "LabResults",
    PdfReportAvailable = "PdfReportAvailable",
    PdfReport = "PdfReport",

    ClientsGetClients = "Clients/GetClients",
    ClientsGetClient = "Clients/GetClient",
    ClientsResetPassword = "Clients/ResetPassword",
    ClientsCreateClient = "Clients/CreateClient",
    ClientsUpdateClient = "Clients/UpdateClient",

    SuppliesGetSupplies = "Supplies/GetSupplies",
    SuppliesGetSupplyOrders = "Supplies/GetSupplyOrders",
    SuppliesCreateSupplyOrder = "Supplies/CreateSupplyOrder",
    SuppliesSaveSupplyLevels = "Supplies/SaveSupplyLevels",
    SuppliesGetSupplyOrderPdf = "Supplies/GetSupplyOrderPdf",

    CourierRouteSheetsGetCourierRouteSheets = "CourierRouteSheets/GetCourierRouteSheets",
    Users = "Users",
}

@autoinject
export class DataService {
    _authenticationService: AuthenticationService;

    constructor(authenticationService: AuthenticationService) {
        this._authenticationService = authenticationService;
    }

    // async getValueFromServerAsync(valueId: string) {
    //     return await this.getSingleDataFromServerAsync(DataServiceControllers.Values, valueId, null);
    // }

    async getDashboardDataFromServerAsync(parameters) {
        return await this.getListDataFromServerAsync(
            DataServiceControllers.Dashboard,
            parameters
        );
    }
    async getPatientsDataFromServerAsync(parameters) {
        return await this.getListDataFromServerAsync(
            DataServiceControllers.Patients,
            parameters
        );
    }
    async getSpeciesAndBreedsDataFromServerAsync(parameters) {
        return await this.getListDataFromServerAsync(
            DataServiceControllers.SpeciesAndBreeds,
            parameters
        );
    }

    async getSupplies(parameters) {
        return await this.getListDataFromServerAsync(
            DataServiceControllers.SuppliesGetSupplies,
            parameters
        );
    }
    async getSupplyOrders(parameters) {
        return await this.getListDataFromServerAsync(
            DataServiceControllers.SuppliesGetSupplyOrders,
            parameters
        );
    }
    async createSupplyOrder(supplyOrder) {
        return await this.postSingleDataAsync(
            DataServiceControllers.SuppliesCreateSupplyOrder,
            supplyOrder
        );
    }
    async saveSupplyLevels(supplies) {
        return await this.postSingleDataAsync(
            DataServiceControllers.SuppliesSaveSupplyLevels,
            supplies
        );
    }

    async getClient(parameters) {
        return await this.getSingleDataFromServerAsync(
            DataServiceControllers.ClientsGetClient,
            null,
            parameters
        );
    }
    async updateClient(client) {
        return await this.updateSingleDataAsync(
            DataServiceControllers.ClientsUpdateClient,
            client
        );
    }
    async resetPasswordAsync(resetPasswordRequest: { userId: string }) {
        return await this.postSingleDataAsync(
            DataServiceControllers.ClientsResetPassword,
            resetPasswordRequest
        );
    }
    async createClient(newClientRequest: {
        username: string;
        clientId: number;
    }) {
        return await this.postSingleDataAsync(
            DataServiceControllers.ClientsCreateClient,
            newClientRequest
        );
    }

    async getCourierRouteSheets(parameters: { date: string }) {
        return await this.getListDataFromServerAsync(
            DataServiceControllers.CourierRouteSheetsGetCourierRouteSheets,
            parameters
        );
    }

    async getClinics(parameters) {
        return await this.getListDataFromServerAsync(
            DataServiceControllers.Clinics,
            parameters
        );
    }
    async getPatients(parameters) {
        return await this.getListDataFromServerAsync(
            DataServiceControllers.Patients,
            parameters
        );
    }
    async getPatient(patientNumber) {
        return await this.getSingleDataFromServerAsync(
            DataServiceControllers.Patients,
            patientNumber,
            null
        );
    }

    async getReviewQueue(parameters) {
        return await this.getListDataFromServerAsync(
            DataServiceControllers.ReviewQueue,
            parameters
        );
    }
    async deleteReviewQueueItem(entity) {
        return await this.deleteDataFromServerByIdAsync(
            DataServiceControllers.ReviewQueue,
            { accession: entity.accession, unitId: entity.unitId }
        );
    }

    async getReportQueue(parameters) {
        return await this.getListDataFromServerAsync(
            DataServiceControllers.ReportQueue,
            parameters
        );
    }
    async deleteReportQueueItem(entity) {
        return await this.deleteDataFromServerByIdAsync(
            DataServiceControllers.ReportQueue,
            { accession: entity.accession, unitId: entity.unitId }
        );
    }

    async getDiagnostics(parameters) {
        return await this.getListDataFromServerAsync(
            DataServiceControllers.Diagnostics,
            parameters
        );
    }
    async updateDiagnostic(diagnostic) {
        return await this.updateSingleDataAsync(
            DataServiceControllers.Diagnostics,
            diagnostic
        );
    }

    async getVeterinariansDataFromServerAsync(
        parameters
    ): Promise<IVeterinarian[]> {
        return await this.getListDataFromServerAsync(
            DataServiceControllers.Veterinarians,
            parameters
        );
    }

    async getAccessions(parameters) {
        return await this.getListDataFromServerAsync(
            DataServiceControllers.Accessions,
            parameters
        );
    }
    async getAccession(accessionId) {
        return await this.getSingleDataFromServerAsync(
            DataServiceControllers.Accessions,
            accessionId,
            null
        );
    }
    async deleteAccession(accessionId) {
        return await this.deleteDataFromServerByIdAsync(
            DataServiceControllers.Accessions,
            { accessionId: accessionId }
        );
    }

    async getPathologistComments(accessionId) {
        return await this.getListDataFromServerAsync(
            DataServiceControllers.PathologistComments,
            { accessionId: accessionId }
        );
    }

    async getLabResults(accessionId) {
        return await this.getListDataFromServerAsync(
            DataServiceControllers.LabResults,
            { accessionId: accessionId }
        );
    }

    async getLabRequsitions(parameters) {
        return await this.getListDataFromServerAsync(
            DataServiceControllers.LabRequisitions,
            parameters
        );
    }
    async createLabRequisition(labRequisition) {
        return await this.postSingleDataAsync(
            DataServiceControllers.LabRequisitions,
            labRequisition
        );
    }

    async getPdfAvailability(accessionId) {
        return await this.getSingleDataFromServerAsync(
            DataServiceControllers.PdfReportAvailable,
            null,
            { accessionId: accessionId }
        );
    }
    async downloadPdfReport(accessionId) {
        return await this.getBlobDataFromServerAsync(
            DataServiceControllers.PdfReport,
            null,
            { accessionId: accessionId }
        );
    }
    async downloadSupplyPdf(supplyOrderId) {
        return await this.getBlobDataFromServerAsync(
            DataServiceControllers.SuppliesGetSupplyOrderPdf,
            null,
            { supplyOrderId: supplyOrderId }
        );
    }

    //Generic API Endpoints
    async getListDataFromServerAsync(
        controller: DataServiceControllers,
        requestParameters
    ) {
        let fetchUrl = `/${controller}`;

        if (requestParameters != null)
            fetchUrl = `${fetchUrl}${this.constructQueryString(
                requestParameters
            )}`;

        const returnedData = await fetch(fetchUrl, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${this._authenticationService.session.token}`,
            },
        });

        const jsonUnpackedData = await returnedData.json();

        return jsonUnpackedData;
    }

    async getSingleDataFromServerAsync(
        controller: DataServiceControllers,
        singleDataId: string,
        requestParameters: any
    ) {
        let fetchUrl = `/${controller}/${
            singleDataId != null ? singleDataId : ""
        }`;

        if (requestParameters != null)
            fetchUrl = `${fetchUrl}${this.constructQueryString(
                requestParameters
            )}`;

        const returnedData = await fetch(fetchUrl, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${this._authenticationService.session.token}`,
            },
        });

        const jsonUnpackedData = await returnedData.json();

        return jsonUnpackedData;
    }
    async getBlobDataFromServerAsync(
        controller: DataServiceControllers,
        singleDataId: string,
        requestParameters: any
    ) {
        let fetchUrl = `/${controller}/${
            singleDataId != null ? singleDataId : ""
        }`;

        if (requestParameters != null)
            fetchUrl = `${fetchUrl}${this.constructQueryString(
                requestParameters
            )}`;

        const returnedData = await fetch(fetchUrl, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${this._authenticationService.session.token}`,
            },
        });

        const jsonUnpackedData = await returnedData.blob();

        return jsonUnpackedData;
    }

    async postSingleDataAsync(controller: DataServiceControllers, entity: any) {
        let fetchUrl = `/${controller}`;

        const returnedData = await fetch(fetchUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${this._authenticationService.session.token}`,
            },
            body: JSON.stringify(entity),
        });

        const jsonUnpackedData = await returnedData.json();
        return jsonUnpackedData;
    }

    async updateSingleDataAsync(
        controller: DataServiceControllers,
        entity: any
    ) {
        let fetchUrl = `/${controller}`;

        const returnedData = await fetch(fetchUrl, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${this._authenticationService.session.token}`,
            },
            body: JSON.stringify(entity),
        });

        const jsonUnpackedData = await returnedData.json();
        return jsonUnpackedData;
    }

    async deleteDataFromServerByIdAsync(
        controller: DataServiceControllers,
        requestParameters
    ) {
        let fetchUrl = `/${controller}`;

        if (requestParameters != null)
            fetchUrl = `${fetchUrl}${this.constructQueryString(
                requestParameters
            )}`;

        const returnedData = await fetch(fetchUrl, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${this._authenticationService.session.token}`,
            },
        });
    }

    async deleteSingleDataFromServerAsync(
        controller: DataServiceControllers,
        entity: any
    ) {
        let fetchUrl = `/${controller}`;

        console.log(entity);

        const returnedData = await fetch(fetchUrl, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${this._authenticationService.session.token}`,
            },
            body: JSON.stringify(entity),
        });
    }

    async getTemplateDataFromServerAsync(controller: DataServiceControllers) {
        let fetchUrl = `/${controller}`;

        const returnedData = await fetch(fetchUrl, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });

        const jsonUnpackedData = await returnedData.json();

        return jsonUnpackedData;
    }

    private constructQueryString(parameters) {
        var queryString = "";

        for (var prop in parameters) {
            if (parameters.hasOwnProperty(prop) && parameters[prop] != null) {
                queryString +=
                    queryString == ""
                        ? `?${prop}=${encodeURIComponent(parameters[prop])}`
                        : `&${prop}=${encodeURIComponent(parameters[prop])}`;
            }
        }

        return queryString;
    }
}
